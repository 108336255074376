const config = {
  // apiUrl: "http://localhost/trocknerheld_workflow_backend/index.php",
  // imgApiUrl: "http://localhost/trocknerheld_workflow_backend/uploads",
  // apiBaseUrl: "http://localhost/trocknerheld_workflow_backend/",
  apiUrl: "/server/index.php",
  imgApiUrl: "/server/uploads",
  apiBaseUrl: "/server/",
};

export default config;
