import React, { useState } from "react";
import { useLogin, useNotify } from "react-admin";
import {
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const CustomLoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ username, password }).catch(() =>
      notify("Invalid username or password")
    );
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "flex-start",
          backgroundImage:
            "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Box
          sx={{
            marginTop: "10vh",
            minWidth: 300,
            p: 2,
            pb: 3,
            backgroundColor: "white",
            borderRadius: 1,
            color: "rgba(0, 0, 0, 0.87)",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            boxShadow:
              "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
            overflow: "hidden",
          }}
        >
          <div
            class="RaLogin-avatar"
            style={{
              marginBottom: "25px",
              marginTop: "8px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault RaLogin-icon css-iisrix-MuiAvatar-root">
              <svg
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="LockIcon"
              >
                <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2m-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2m3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1z"></path>
              </svg>
            </div>
          </div>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              id="username"
              label="Username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              // InputProps={{
              //   sx: { height: "40px" }, // Adjust vertical padding here
              // }}
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 400,
                fontSize: "1rem",
                // padding: 0,
                lineHeight: "1.4375em",
                letterSpacing: "0.00938em",
                color: "rgba(0, 0, 0, 0.87)",
                boxSizing: "border-box",
                position: "relative",
                cursor: "text",
                display: "inline-flex",
                alignItems: "center",
                width: "100%",
                borderRadius: "4px",
              }}
            />
          </Box>
          <Box sx={{ mb: 2, paddingBottom: "12px" }}>
            <TextField
              fullWidth
              id="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Button variant="contained" type="submit" color="primary" fullWidth>
            Login
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default CustomLoginPage;
